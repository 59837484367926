import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer style={styles.footer}>
            <div style={styles.container}>
                <div style={styles.contactInfo}>
                    <h3>Contact Info</h3>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} style={styles.icon} />
                        <span>Mumbai, Maharashtra</span>
                    </div>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faPhone} style={styles.icon} />
                        <span>+91 7756819075</span>
                    </div>
                    <div style={styles.contactItem}>
                        <FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
                        <span>lagnachabedi@gmail.com</span>
                    </div>
                </div>
                <div style={styles.socialIcons}>
                    <h3>Follow Us</h3>
                    <div style={styles.socialLinks}>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2x" style={styles.socialIcon} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} size="2x" style={styles.socialIcon} />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" style={styles.socialIcon} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" style={styles.socialIcon} />
                        </a>
                    </div>
                </div>
            </div>
            <div style={styles.copyRight}>
                <a href="/terms&condition">Terms&condition</a>
            </div>
            <div style={styles.copyRight}>
                &copy; 2024 Your Company Name. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;

const styles = {
    footer: {
        backgroundColor: '#1f2225',
        color: '#495057',
        padding: '40px 20px',
        marginTop: 'auto',
        borderTop: '1px solid #dee2e6'
    },
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    contactInfo: {
        flex: '1 1 300px',
        marginBottom: '20px',
        marginRight: '20px'
    },
    contactItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    socialIcons: {
        flex: '1 1 300px',
        marginBottom: '20px'
    },
    socialLinks: {
        display: 'flex'
    },
    socialIcon: {
        marginRight: '10px',
        color: '#495057',
        transition: 'color 0.3s ease-in-out',
        cursor: 'pointer'
    },
    copyRight: {
        fontSize: '14px',
        textAlign: 'center',
        marginTop: '20px'
    },
    '@media (max-width: 768px)': {
        container: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        contactInfo: {
            marginBottom: '20px',
            marginRight: '0'
        },
        socialIcons: {
            marginBottom: '20px'
        }
    }
};
