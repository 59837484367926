import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mt-5">
      <h1>Terms and Conditions</h1>
      <p>
        To be enrolled with the <strong>lagnachibedi.in</strong> Site ("Website"), you must be a registered member of the site ("Member") and agree to be bound by these Terms of Service ("Agreement"). Please avail of our services ("Service") and post your professional/corporate details ("Content") for hosting after reading through our Service Agreement.
      </p>

      <h2>Service Agreement</h2>
      <p>
        To become a member of the <strong>lagnachibedi.in</strong> service, you must:
      </p>
      <ul>
        <li>First of all, read and accept all of the Terms and Conditions.</li>
        <li>We may modify the terms of this Agreement or the Privacy Policy, at our sole discretion. Your continued use of the service indicates your acceptance of the amended Terms of Service Agreement.</li>
        <li>To register, you must submit a valid email address and mobile number. The password to log in will be generated and sent to your registered email ID.</li>
      </ul>

      <h3>The Process</h3>
      <ul>
        <li>You must be an Indian citizen (or of foreign origin) of legal marriageable age as per the laws of the country and within the limits specified on this website.</li>
        <li>You must be a member of the <strong>lagnachibedi Syrian Catholic Community</strong>.</li>
        <li>To register, you must submit a valid email address and mobile number.</li>
        <li>The password to log in will be generated and sent to your registered email ID.</li>
        <li>Log in with your username (your email ID) and password, and update your profile.</li>
        <li>Upload your photographs, update your personal details, and mark your partner preferences.</li>
        <li>Pay the registration fee through the online payment gateway.</li>
        <li>Your registration details and profile will be examined and approved within 24 hours. You can then view the best matches based on your profile and preferences.</li>
        <li>You can send and receive interests and shortlist profiles.</li>
      </ul>

      <h3>Eligibility Criteria</h3>
      <ul>
        <li>You must be an Indian citizen (or of foreign origin) of legal marriageable age as per the laws of the country and within the limits specified on this website.</li>
        <li>You must be a member of the <strong>lagnachibedi Syrian Catholic Community</strong>.</li>
      </ul>

      <h3>Membership Term</h3>
      <ul>
        <li>The current membership fee is <strong>Rs. 799/- for two months</strong> registration.</li>
        <li>You may cancel your membership at any time, following matrimonial engagement or for any other reason.</li>
        <li>After two months, the membership has to be renewed at <strong>Rs. 319/- for another two months</strong>. After two renewals, the fee is exempted.</li>
        <li><strong>lagnachibedi.in</strong> may cancel membership due to misconduct by a member, if brought to our notice.</li>
        <li>Fees once paid will not be refunded under any circumstances.</li>
      </ul>

      <h3>Content Collation</h3>
      <p>
        <strong>lagnachibedi.in</strong> is an internet-based online matrimonial service where member details are hosted online. Members are solely responsible for the content they provide. We will not be held responsible for any incorrect or inaccurate content due to faults or technical errors made by our members.
      </p>
      <p>
        Explicit or indecent text material will either be doctored or deleted by our staff. Multiple profiles of the same person will not be accepted. Hot-linking, or setting HTML to refer to a site that results in bandwidth loss, is prohibited. <strong>lagnachibedi.in</strong> does not control, endorse, or vary any information provided by members, including links, events, messages, or message boards.
      </p>
      <p>
        Members must notify <strong>lagnachibedi.in</strong> immediately of any unauthorized use of their account. Members are responsible for maintaining the confidentiality of their username and password, and all uses of their account.
      </p>

      <h3>Member Disputes</h3>
      <p>
        Members are responsible for their interactions with other members. <strong>lagnachibedi.in</strong> does not monitor disputes between members or visitors, whether online or offline.
      </p>

      <h3>Copyright Infringement</h3>
      <p>
        <strong>lagnachibedi.in</strong> owns and retains proprietary rights over text, images, graphics, and other proprietary information on the website. Reproducing, copying, or modifying part of the website without permission may result in legal action under the Indian Copyright Act, 1957.
      </p>

      <h2>Privacy Policy</h2>
      <h3>Salient Features of our Privacy Policy:</h3>
      <ul>
        <li><strong>lagnachibedi.in</strong> is strongly committed to maintaining the privacy of our members and uses a highly secure server for credit card transactions.</li>
        <li>We may be required by law to provide information about a client in legal proceedings.</li>
        <li><strong>lagnachibedi.in</strong> cannot vouch for any claims made by individual members and shall not be held liable if such claims are not honored. Verification of these claims is strongly advised.</li>
      </ul>

      <h2>Policy & Cancellation</h2>
      <h3>Refund Policy</h3>
      <p>
        As per our refund policy, <strong>lagnachibedi.in</strong> does not entertain refund requests for membership or subscription fees. Members gain access to the platform immediately after registration.
      </p>

      <h3>Cancellation Policy</h3>
      <p>
        Either the member or <strong>lagnachibedi.in</strong> may cancel a paid subscription at any time, for any reason or no reason. If cancellation is due to a member's breach of terms, it will become effective immediately. Upon cancellation, all rights and privileges under this policy are null.
      </p>

      <p>If you have any questions, feel free to contact us at: <strong>laganachibedi@gmail.com</strong>.</p>
    </div>
  );
};

export default TermsAndConditions;
