import React from 'react'
import Carousel from '../components/home/carousel/carousel'
import Testimonial from '../components/home/testimonial/testimonial'
import Packages from '../components/home/packages/packages';


function Home() {
  return (
    <>
    <Carousel/>
    <Packages/>
    <Testimonial/>
    </>
  );
}

export default Home
