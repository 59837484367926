import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Login = ({ handleLogin }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('Please login to your account');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log(process.env)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                window.alert('Login Successful!');
                handleLogin(); // Call the handleLogin function to update login state in the App component
                navigate('/'); // Navigate to home page on successful login
            } else {
                const errorData = await response.json();
                setMessage('Login Failed: ' + errorData.error);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setMessage('Error logging in. Please try again.');
        }
    };

    return (
        <section style={styles.loginSection}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-8">
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <h3 className="card-title text-center" style={styles.cardTitle}>Login</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group" style={styles.form_group}>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your Email"
                                            required
                                            style={styles.input}
                                        />
                                    </div>
                                    <div className="form-group" style={styles.form_group}>
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter your Password"
                                            required
                                            style={styles.input}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block" style={styles.button}>
                                        Login
                                    </button>
                                    <p className="text-center mt-3" style={styles.link}>
                                        <NavLink to="/register" style={styles.navLink}>Don't have an account? Register here!</NavLink>
                                    </p>
                                    {message && <p className="text-center text-danger">{message}</p>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;

const styles = {
    loginSection: {
        padding: '150px 0',
        backgroundColor: '#f8f9fa', // Light background color
    },
    card: {
        border: '1px solid #dee2e6', // Subtle border
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', // Box shadow for depth
    },
    form_group: {
        padding: '10px 10px'
    },
    cardTitle: {
        color: 'rgb(128, 0, 128)', // Primary color for title
    },
    input: {
        border: '1px solid #ced4da', // Input border color
    },
    button: {
        backgroundColor: 'rgb(128, 0, 128)', // Primary color for button
        borderColor: '#007bff',
        margin: '30px auto',
        display: 'block',
    },
    link: {
        textAlign: 'center',
        marginTop: '1rem',
    },
    navLink: {
        color: '#007bff', // Link color
    },
};
