import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
            'YOUR_TEMPLATE_ID', // Replace with your EmailJS template ID
            formData,
            'YOUR_USER_ID' // Replace with your EmailJS user ID
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            // Clear form after successful submission
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            alert('Message sent successfully!');
        }).catch((err) => {
            console.error('Failed to send message:', err);
            alert('Failed to send message. Please try again later.');
        });
    };

    return (
        <div style={styles.container}>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label htmlFor="name" style={styles.label}>Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder='Enter your name'
                    value={formData.name}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />

                <label htmlFor="email" style={styles.label}>Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder='Enter your email'
                    value={formData.email}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />

                <label htmlFor="subject" style={styles.label}>Subject:</label>
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Write your subject here"
                    value={formData.subject}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />

                <label htmlFor="message" style={styles.label}>Message:</label>
                <textarea
                    id="message"
                    name="message"
                    rows="8"
                    placeholder='Write your message here'
                    value={formData.message}
                    onChange={handleChange}
                    style={styles.input}
                    required
                ></textarea>

                <button type="submit" style={styles.button}>Submit</button>
            </form>
        </div>
    );
};

export default Contact;

const styles = {
    container: {
        color: 'rgb(128,0,128)',
        background: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        width: '400px',
        margin: '100px auto'
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        color: 'rgb(0,0,0)',
        marginBottom: '8px'
    },
    input: {
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px'
    },
    button: {
        backgroundColor: 'RGB(128,0,128)',
        color: 'white',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        margin: '20px 20px'
    }
};

/*Sign up for an EmailJS account (https://www.emailjs.com/)if you haven't already.
Create a new email service in the EmailJS dashboard.
Create a new email template in the EmailJS dashboard.
Copy the service ID, template ID, and user ID from your EmailJS dashboard and replace 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', and 'YOUR_USER_ID' in the code above with these values.
By integrating EmailJS, you can send emails directly from the frontend without needing a backend server. Adjust the form fields and EmailJS configuration as needed for your application. */