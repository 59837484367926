import React, { useEffect } from 'react';
import MyImage from './assests/logo.jpg';
import { NavLink, useNavigate } from 'react-router-dom';
import './navbar.css';

const Navbar = ({ isLoggedIn, handleLogout }) => {
  const navigate = useNavigate();

  const handleNavLinkClick = () => {
    const navbarToggler = document.getElementById('navbarNav');
    if (navbarToggler.classList.contains('show')) {
      navbarToggler.classList.remove('show');
    }
  };

  const handleLogoutClick = () => {
    handleLogout();
    navigate('/'); // Redirect to the home page
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={MyImage} alt="Logo" width="90" height="70" className="d-inline-block align-text-top" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto flex">
            {!isLoggedIn ? (
              <>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link" onClick={handleNavLinkClick}>Login</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/register" className="nav-link" onClick={handleNavLinkClick}>Register</NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink to="/" className="nav-link" onClick={handleNavLinkClick}>Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/profile" className="nav-link" onClick={handleNavLinkClick}>Update Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/myprofile" className="nav-link" onClick={handleNavLinkClick}>My Profile</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/viewprofile" className="nav-link" onClick={handleNavLinkClick}>View Profiles</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/packages" className="nav-link" onClick={handleNavLinkClick}>Packages</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/success_stories" className="nav-link" onClick={handleNavLinkClick}>Success Stories</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link" onClick={handleNavLinkClick}>Contact Us</NavLink>
                </li>
                <li className="nav-item">
                  <button className="btn btn-outline-danger" onClick={handleLogoutClick}>Logout</button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
