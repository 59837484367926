import React from 'react'

const Testimonial = () => {
  return (
    <div style={{backgroundColor:'#F2F3F5'}}>
      <div class="text-center section-title mt-5 mb-5 " >
            <h2 class="fw-600 mb-3 " style={{ color: 'rgb(128, 0, 128)' }}>Happy Stories</h2>
           </div>
    
          <div class="row mt-5 mb-5">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>
                  <p class="card-text">"लग्नाची बेडी ने मला प्रेमात पडण्याचे कारण दिले. तुमच्या सेवांद्‌वारे मला माझा योग्य जोडीदार मिळाला. मला शोभणारे स्थळ शोधल्याबद्दल लग्नाची बेडी मैट्रिमानी चे आभार"</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title"></h5>
                  <p class="card-text">"लग्नाची बेडी एक विश्वास ठेवण्या योग्य विवाहसंस्था आहे.माझ्या आयुष्यातील मनासारखा जोडीदार ह्या संस्थेमुळे मला मिळाला.तसेच लग्नाची बेडी ची संपूर्ण टीम खुप सपोर्ट करणारी आहे"</p>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Testimonial
