import React, { useState } from "react";

function Profile() {
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    cast: "",
    height: "",
    weight: "",
    bloodGroup: "",
    education: "",
    jobBusiness: "",
    salary: "",
    fatherName: "",
    motherName: "",
    siblings: "",
    address: "",
    contactNumber: "",
    agePreference: "",
    heightPreference: "",
    castPreference: "",
    educationPreference: "",
    jobPreference: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: "include", // This is important for sending cookies
      });

      const data = await response.json();

      if (data.success) {
        alert("User data updated successfully!");
      } else {
        throw new Error(data.error || "Failed to update user data");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      alert("Failed to update user data. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ color: "rgb(128, 0, 128)" }}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="dob" className="form-label">
            Date of Birth:
          </label>
          <input
            type="date"
            className="form-control"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
            <label htmlFor="cast" className="form-label">
              Cast:
            </label>
            <select
              className="form-select"
              id="cast"
              name="cast"
              value={formData.cast}
              onChange={handleChange}
              required
            >
              <option value="">Select Cast</option>
              <option value="Hindu Maratha">Hindu Maratha</option>
              <option value="96 Koli Maratha">96 Koli Maratha</option>
              <option value="Kunbi">Kunbi</option>
              <option value="Bhandari">Bhandari</option>
              <option value="Vanjari">Vanjari</option>
              <option value="Lingayat">Lingayat</option>
              <option value="Brahmhan">Brahmhan</option>
              <option value="Budhist">Budhist</option>
              <option value="Vaishy Vani">Vaishy Vani</option>
              <option value="Agri">Agri</option>
              <option value="Koli">Koli</option>
              <option value="Adiwasi">Adiwasi</option>
              <option value="Dhangar">Dhangar</option>
              <option value="Matang">Matang</option>
              <option value="Chabhar">Chabhar</option>
              <option value="Sonari">Sonari</option>
              <option value="Kumbhar">Kumbhar</option>
              <option value="Banjari">Banjari</option>
              <option value="Full Mali">Full Mali</option>
            </select>
          </div>

        <div className="mb-3">
          <label htmlFor="height" className="form-label">
            Height:
          </label>
          <input
            type="text"
            className="form-control"
            id="height"
            name="height"
            value={formData.height}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="weight" className="form-label">
            Weight:
          </label>
          <input
            type="text"
            className="form-control"
            id="weight"
            name="weight"
            value={formData.weight}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="bloodGroup" className="form-label">
            Blood Group:
          </label>
          <input
            type="text"
            className="form-control"
            id="bloodGroup"
            name="bloodGroup"
            value={formData.bloodGroup}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="education" className="form-label">
            Education:
          </label>
          <input
            type="text"
            className="form-control"
            id="education"
            name="education"
            value={formData.education}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobBusiness" className="form-label">
            Job/Business:
          </label>
          <input
            type="text"
            className="form-control"
            id="jobBusiness"
            name="jobBusiness"
            value={formData.jobBusiness}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="salary" className="form-label">
            Salary:
          </label>
          <input
            type="text"
            className="form-control"
            id="salary"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="fatherName" className="form-label">
            Father's Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="fatherName"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="motherName" className="form-label">
            Mother's Name:
          </label>
          <input
            type="text"
            className="form-control"
            id="motherName"
            name="motherName"
            value={formData.motherName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="siblings" className="form-label">
            Siblings:
          </label>
          <input
            type="text"
            className="form-control"
            id="siblings"
            name="siblings"
            value={formData.siblings}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address:
              </label>
              <select
                className="form-select"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              >
                <option value="">Select Address</option>
                <option value="Location">Location</option>
                <option value="Thane">Thane</option>
                <option value="Nashik">Nashik</option>
                <option value="Pune">Pune</option>
                <option value="Dhule">Dhule</option>
                <option value="Jalgaon">Jalgaon</option>
                <option value="Buldhana">Buldhana</option>
                <option value="Akola">Akola</option>
                <option value="Amravati">Amravati</option>
                <option value="Nagpur">Nagpur</option>
                <option value="Vardha">Vardha</option>
                <option value="Bhandara">Bhandara</option>
                <option value="Yavatmal">Yavatmal</option>
                <option value="Nanded">Nanded</option>
                <option value="Parbhani">Parbhani</option>
                <option value="Bidi">Bidi</option>
                <option value="Aurangabad">Aurangabad</option>
                <option value="Ahmadnagar">Ahmadnagar</option>
                <option value="Usmanabad">Usmanabad</option>
                <option value="Solapur">Solapur</option>
                <option value="Sangli">Sangli</option>
                <option value="Satara">Satara</option>
                <option value="Kolhapur">Kolhapur</option>
                <option value="Ratnagiri">Ratnagiri</option>
                <option value="Gadchiroli">Gadchiroli</option>
                <option value="Goa">Goa</option>
              </select>
            </div>

        <div className="mb-3">
          <label htmlFor="contactNumber" className="form-label">
            Contact Number:
          </label>
          <input
            type="text"
            className="form-control"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>

        {/* Partner Preference */}
        <div style={{ color: "black" }}>
          <h2>Partner Preference</h2>
        </div>
        <div className="mb-3">
          <label htmlFor="agePreference" className="form-label">
            Age Preference:
          </label>
          <input
            type="text"
            className="form-control"
            id="agePreference"
            name="agePreference"
            value={formData.agePreference}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="heightPreference" className="form-label">
            Height Preference:
          </label>
          <input
            type="text"
            className="form-control"
            id="heightPreference"
            name="heightPreference"
            value={formData.heightPreference}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="castPreference" className="form-label">
            Cast Preference:
          </label>
          <input
            type="text"
            className="form-control"
            id="castPreference"
            name="castPreference"
            value={formData.castPreference}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="educationPreference" className="form-label">
            Education Preference:
          </label>
          <input
            type="text"
            className="form-control"
            id="educationPreference"
            name="educationPreference"
            value={formData.educationPreference}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobPreference" className="form-label">
            Job Preference:
          </label>
          <input
            type="text"
            className="form-control"
            id="jobPreference"
            name="jobPreference"
            value={formData.jobPreference}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary mt-5 mb-5" style={{ backgroundColor: 'rgb(128, 0, 128)', color: '#fff' }}>
          Submit
        </button>
      </div>
    </form>
  );
}

export default Profile;