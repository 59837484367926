// UserProfile.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
        credentials: "include",
      });
      const data = await response.json();
      if(response.ok){
        setUserData(data);
      }
      else setUserData(null);
      console.log(userData)
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!userData) return <div>No user data found. <Link to="/profile">Create Now.</Link></div>;

  return (
    <div className="user-profile">
      <h1 style={{ color: 'rgb(128, 0, 128)' }}>User Profile</h1>
      <div>
        <strong>Name:</strong> {userData.name}
      </div>
      <div>
        <strong>Date of Birth:</strong> {userData.dob}
      </div>
      <div>
        <strong>Cast:</strong> {userData.cast}
      </div>
      <div>
        <strong>Height:</strong> {userData.height}
      </div>
      <div>
        <strong>Weight:</strong> {userData.weight}
      </div>
      <div>
        <strong>Blood Group:</strong> {userData.bloodGroup}
      </div>
      <div>
        <strong>Education:</strong> {userData.education}
      </div>
      <div>
        <strong>Job/Business:</strong> {userData.jobBusiness}
      </div>
      <div>
        <strong>Salary:</strong> {userData.salary}
      </div>
      <div>
        <strong>Father's Name:</strong> {userData.fatherName}
      </div>
      <div>
        <strong>Mother's Name:</strong> {userData.motherName}
      </div>
      <div>
        <strong>Siblings:</strong> {userData.siblings}
      </div>
      <div>
        <strong>City:</strong> {userData.address}
      </div>
      <div>
        <strong>Contact Number:</strong> {userData.contactNumber}
      </div>
      <h2>Partner Preference</h2>
      <div>
        <strong>Age Preference:</strong>{" "}
        {userData.partnerPreference.agePreference}
      </div>
      <div>
        <strong>Height Preference:</strong>{" "}
        {userData.partnerPreference.heightPreference}
      </div>
      <div>
        <strong>Cast Preference:</strong>{" "}
        {userData.partnerPreference.castPreference}
      </div>
      <div>
        <strong>Education Preference:</strong>{" "}
        {userData.partnerPreference.educationPreference}
      </div>
      <div>
        <strong>Job Preference:</strong>{" "}
        {userData.partnerPreference.jobPreference}
      </div>
      <br />
    </div>
  );
}

export default UserProfile;