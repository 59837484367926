import React, { useEffect, useState } from "react";
import Placeholder from "../components/vies/placeholder";
import { Link } from "react-router-dom";

export default function View() {
  const [profiles, setProfiles] = useState();
  const [loading, setLoader] = useState(true);
  async function helper(isMounted) {
    setLoader(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/view`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const res = await response.json();
      if (isMounted) setProfiles(res);
      else throw new Error();
    } catch (e) {
      setProfiles({ error: false });
      console.log(e);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    let isMounted = true;
    helper(isMounted);
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div style={{ width: "100%", height: "100%", display : 'flex' , justifyContent : 'center', alignContent : 'center'}}>
      {!loading && !profiles?.error && (
        <Placeholder profiles={profiles?.message} />
      )}
      {!loading && !profiles?.error && !profiles?.message?.length && <div>No users found.</div>}
      {!loading && profiles?.error && (
         <div className="mt-4 d-flex justify-content-center">
         <a href="/packages" className="btn btn-primary" style={{ backgroundColor: 'rgb(128, 0, 128)', color: '#fff' }}>Purchase These Package</a>
       </div>
      )}
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          Loading....
        </div>
      )}
    </div>
  );
}
