import React, { useState } from 'react';

import './view.css';

const ProfileCard = ({ profile, onClick }) => {
  return (
    <div className="profile-card" onClick={() => onClick(profile)}>
      <h3>{profile.name}</h3>
      <p><strong>Email:</strong> {profile.email}</p>
      <p><strong>Age:</strong> {calculateAge(profile.dob)}</p>
      <p><strong>Occupation:</strong> {profile.jobBusiness}</p>
    </div>
  );
};

const ProfileModal = ({ profile, onClose }) => {
  if (!profile) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>{profile.name}</h2>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="profile-details">
          <p><strong>Email:</strong> {profile.email}</p>
          <p><strong>Date of Birth:</strong> {formatDate(profile.dob)}</p>
          <p><strong>Cast:</strong> {profile.cast}</p>
          <p><strong>Height:</strong> {profile.height}</p>
          <p><strong>Weight:</strong> {profile.weight}</p>
          <p><strong>Blood Group:</strong> {profile.bloodGroup}</p>
          <p><strong>Education:</strong> {profile.education}</p>
          <p><strong>Job/Business:</strong> {profile.jobBusiness}</p>
          <p><strong>Salary:</strong> {profile.salary}</p>
          <p><strong>Father's Name:</strong> {profile.fatherName}</p>
          <p><strong>Mother's Name:</strong> {profile.motherName}</p>
          <p><strong>Siblings:</strong> {profile.siblings}</p>
          <p><strong>Address:</strong> {profile.address}</p>
          <p><strong>Contact Number:</strong> {profile.contactNumber}</p>
        </div>
        <div className="partner-preference">
          <h3>Partner Preference</h3>
          <p><strong>Age:</strong> {profile.partnerPreference.agePreference}</p>
          <p><strong>Height:</strong> {profile.partnerPreference.heightPreference}</p>
          <p><strong>Cast:</strong> {profile.partnerPreference.castPreference}</p>
          <p><strong>Education:</strong> {profile.partnerPreference.educationPreference}</p>
          <p><strong>Job:</strong> {profile.partnerPreference.jobPreference}</p>
        </div>
      </div>
    </div>
  );
};

const ProfileDisplay = ({ profiles }) => {
  const [selectedProfile, setSelectedProfile] = useState(null);

  return (
    <div className="profile-container">
      {profiles?.map((profile) => (
        <ProfileCard 
          key={profile._id} 
          profile={profile} 
          onClick={() => setSelectedProfile(profile)}
        />
      ))}
      <ProfileModal 
        profile={selectedProfile} 
        onClose={() => setSelectedProfile(null)}
      />
    </div>
  );
};

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);
  return Math.abs(age.getUTCFullYear() - 1970);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

export default ProfileDisplay;