import React from 'react'
import './carousel.css'
import one from './assests/1.jpg'

const Carousel = () => {
  return (
    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
       <div className='size'>
            <div className="carousel-item active" data-bs-interval="500">
                <img src={one} className="d-block w-100" alt="..." />
                <div className="position-absolute top-0 start-50 translate-middle-x p-3 text-center fw-bold" style={{ color: 'rgb(128,0,128)' }}>
                    <pre className="mb-0">हळदीचा वास आणि मेंदीचा रंग, खुलवेल तुमच्या आयुष्यात प्रेमाचा नवा रंग</pre>
                  </div>
                </div>
            </div>
       </div>
    </div>
  )
}

export default Carousel
