import React from 'react';
import image from './price.jpg';

const Packages = () => {
  return (
    <>
      <div className="container text-center">
        <div className="section-title mt-5 mb-5">
          <h2 className="fw-600 mb-3" style={{ color: 'rgb(128, 0, 128)' }}>Packages</h2>
        </div>
    
        <img src={image} alt="" className="img-fluid" />
        
        <div className="mt-4 d-flex justify-content-center">
          <a href="/packages" className="btn btn-primary" style={{ backgroundColor: 'rgb(128, 0, 128)', color: '#fff' }}>Purchase These Package</a>
        </div>
      </div>
    </>
  );
}

export default Packages;
