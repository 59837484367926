import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/home/navbar/navbar"; // Adjust path as per your file structure
import Home from "./pages/home"; // Adjust paths for your pages
import Login from "./pages/login";
import Register from "./pages/register";
import Profile from "./pages/profile";
import Contact from "./pages/contact";
import Packages from "./components/packages/packages";
import Footer from "./pages/footer";
import UserProfile from "./pages/myprofile";
import View from "./pages/view";
import PhonePePayment from "./pages/phonepay";
import TermsAndConditions from "./pages/term";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("token") !== null;
  });

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div style={{display:"flex", justifyContent:"space-between", minHeight:"100vh",flexDirection:"column"}}>
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={<Login handleLogin={handleLogin} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/myprofile" element={<UserProfile />} />
            <Route path="/viewprofile" element={<View />} />
            <Route path="/payment" element={<PhonePePayment />} />
            <Route path="/terms&condition" element={<TermsAndConditions/>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
