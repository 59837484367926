import React from "react";
import { useNavigate } from "react-router-dom";
import fifty from "./assests/fifty.jpg";
import final from "./assests/final.jpg";
import seven from "./assests/seven.jpg";
import tweleve from "./assests/tweleve.jpg";
import twenety from "./assests/twenety.jpg";

const Packages = () => {
  const navigate = useNavigate();

  const onBuyClick = (id, amount) => {
    // Navigate to the payment page and pass the state (id and amount)
    navigate("/payment", { state: { id, amount } });
  };

  return (
    <div className="container">
      <h1 style={{ color: "rgb(128, 0, 128)" }}>Packages</h1>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="position-relative">
            <img src={tweleve} className="img-fluid mt-5 mb-3" alt="Package 1" />
            <div className="text-center">
              <button
                className="btn btn-purple"
                style={{ backgroundColor: "rgb(128, 0, 128)", color: "#fff" }}
                onClick={() => onBuyClick(1, 1200)}
              >
                BUY
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="position-relative">
            <img src={twenety} className="img-fluid mt-5 mb-3" alt="Package 2" />
            <div className="text-center">
              <button
                className="btn btn-purple"
                style={{ backgroundColor: "rgb(128, 0, 128)", color: "#fff" }}
                onClick={() => onBuyClick(2, 2500)}
              >
                BUY
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="position-relative">
            <img src={seven} className="img-fluid mt-5 mb-3" alt="Package 3" />
            <div className="text-center">
              <button
                className="btn btn-purple"
                style={{ backgroundColor: "rgb(128, 0, 128)", color: "#fff" }}
                onClick={() => onBuyClick(3, 799)}
              >
                BUY
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="position-relative">
            <img src={fifty} className="img-fluid mt-5 mb-3" alt="Package 4" />
            <div className="text-center">
              <button
                className="btn btn-purple"
                style={{ backgroundColor: "rgb(128, 0, 128)", color: "#fff" }}
                onClick={() => onBuyClick(4, 5000)}
              >
                BUY
              </button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="position-relative">
            <img src={final} className="img-fluid mt-5 mb-3" alt="Package 5" />
            <div className="text-center">
              <button
                className="btn btn-purple"
                style={{ backgroundColor: "rgb(128, 0, 128)", color: "#fff" }}
                onClick={() => onBuyClick(5, 10000)}
              >
                BUY
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
