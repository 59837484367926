import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Use this for navigation state
import 'bootstrap/dist/css/bootstrap.min.css';

const PhonePePayment = () => {
  const location = useLocation();
  const { name: locationName, email: locationEmail, phoneNumber: locationPhoneNumber, amount: locationAmount } = location.state || {};

  const [userData, setUserData] = useState({
    name: locationName || '',
    email: locationEmail || '',
    phoneNumber: locationPhoneNumber || '',
    amount: locationAmount || ''
  });

  useEffect(() => {
    // Fetch user data if not passed via location state
    if (!locationName || !locationEmail || !locationPhoneNumber || !locationAmount) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile`, {
            credentials: "include",
          });

          if (response.ok) {
            const data = await response.json();
            setUserData({
              name: data.name || userData.name,
              email: data.email || userData.email,
              phoneNumber: data.contactNumber || userData.phoneNumber,
              amount: userData.amount // Keep the amount as-is if provided in location state
            });
          } else {
            console.error("Failed to fetch user data.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      };

      fetchUserData();
    }
  }, [locationName, locationEmail, locationPhoneNumber, locationAmount]);

  const handlePayment = () => {
    if (!userData.amount) {
      alert("Amount is not specified.");
      return;
    }

    alert(`Proceeding to pay ₹${userData.amount} via PhonePe for ${userData.name}`);

    // Implement the actual payment gateway integration or redirection here
    // Example:
    // window.location.href = "https://www.phonepe.com";
  };

  return (
    <div className="container mt-5">
      <div className="card p-4">
        <h2 className="text-center mb-4">Proceed with PhonePe Payment</h2>

        <div className="mb-3">
          <strong>Name:</strong> <span>{userData.name}</span>
        </div>

        <div className="mb-3">
          <strong>Email:</strong> <span>{userData.email}</span>
        </div>

        <div className="mb-3">
          <strong>Phone Number:</strong> <span>{userData.phoneNumber}</span>
        </div>

        <div className="mb-3">
          <strong>Amount:</strong> <span>₹{userData.amount}</span>
        </div>

        <button
          onClick={handlePayment}
          className="btn btn-block"
          style={{
            backgroundColor: '#5F259F',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            width: '100%'
          }}
        >
          Pay with PhonePe
        </button>
      </div>
    </div>
  );
};

export default PhonePePayment;
