import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: "",
        email: "",
        phone: "",
        gender: "", // New field for gender
        password: "",
        cpassword: ""
    });

    const handleInputs = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const PostData = async (e) => {
        e.preventDefault();
    
        const { username, email, phone, gender, password, cpassword } = user; // Include gender
        console.log(process.env.REACT_APP_API_URL)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username, email, phone, gender, password, cpassword // Include gender
                })
            });
    
            const data = await res.json();
    
            if (res.status === 201) {
                localStorage.setItem('token', data.token);
                window.alert("Registration Successful");
                navigate('/');
            } else {
                window.alert("Registration Failed: " + (data.error || "Unknown error"));
            }
        } catch (error) {
            console.error('Error registering user:', error);
            window.alert("Error registering user. Please try again.");
        }
    };

    return (
        <section style={styles.section}>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="text-center mt-5" style={styles.text}>
                            <h1>Welcome!</h1>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card p-4" style={styles.card}>
                            <h3 className="card-title text-center mb-4" style={styles.text}>Register</h3>
                            <form onSubmit={PostData}>
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="username">Name</label>
                                    <input type="text" className="form-control" id="username" name="username" value={user.username} onChange={handleInputs} placeholder="Enter your name" required />
                                </div>
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" value={user.email} onChange={handleInputs} placeholder="Enter your Email" required />
                                </div>
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="phone">Phone No.</label>
                                    <input type="tel" className="form-control" id="phone" name="phone" value={user.phone} onChange={handleInputs} placeholder="Enter your Phone No." />
                                </div>
                                {/* New gender select form group */}
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="gender">Gender</label>
                                    <select className="form-control" id="gender" name="gender" value={user.gender} onChange={handleInputs} required>
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" id="password" name="password" value={user.password} onChange={handleInputs} placeholder="Enter your Password" required />
                                </div>
                                <div className="form-group" style={styles.form_group}>
                                    <label htmlFor="cpassword">Confirm password</label>
                                    <input type="password" className="form-control" id="cpassword" name="cpassword" value={user.cpassword} onChange={handleInputs} placeholder="Confirm password" required />
                                </div>
                                <div className="text-center mt-4">
                                    <NavLink to='/login' style={styles.navLink}>Already Registered? Login here!</NavLink>
                                </div>
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block" style={styles.button}>Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;

// ... (styles remain unchanged)

const styles = {
    section: {
        backgroundColor: '#f8f9fa',
        paddingTop: '80px',
        paddingBottom: '80px'
    },
    form_group: {
        padding: '10px',
    },
    card: {
        border: '1px solid #dee2e6',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    text: {
        color: 'rgb(128,0,128)',
    },
    navLink: {
        color: '#007bff',
        textDecoration: 'none',
        display: 'inline-block',
        marginTop: '10px',
    },
    button: {
        backgroundColor: 'rgb(128,0,128)',
        borderColor: 'rgb(128,0,128)',
    },
};
